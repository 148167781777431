import React from 'react';
import { Link } from 'gatsby';
import { Layout, Container, Button } from 'components/common';
import { Header } from 'components/theme';
import { Courses } from 'components/landing';
import JenkinsThumbnail from '../assets/images/jenkins_v1.png';

const udemyLink =
  'https://www.udemy.com/course/jenkins-ci-cd-pipelines-devops-for-beginners/?couponCode=JENKINS_YOUTUBE_2024';

export default () => (
  <Layout>
    <Header />
    <Container>
      <h2>Complete Jenkins, CI/CD, and DevOps Course for Beginners (2024)</h2>
      <img alt="Jenkins course" src={JenkinsThumbnail} />
      <h4>Continue your Jenkins journey with this 10+ hours complete Jenkins course.</h4>
      <Button as={Link} href={udemyLink}>
        Learn more about the course
      </Button>
      <br />
      <br />
      <br />
    </Container>
  </Layout>
);
